import React from "react"

import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import richText from "../utils/richText"
import { Body, BodyLarge, Header2 } from "../components/typography"
import colors from "../styles/colors"
import styled from "styled-components"
import Button from "../components/common/PrimaryButton"

import Seo from "../components/seo"

const Header = styled.div`
  color: ${colors.creamLight};

  margin: 0 auto;

  padding: 48px 24px;

  border-bottom: 1px solid ${colors.creamLight};

  text-align: center;
`

const Wrapper = styled.div`
  color: ${colors.creamLight};
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 75%
    );
  background-position: bottom;
  background-size: cover;

  padding: 24px 48px;

  display: flex;
  justify-content: center;

  min-height: 70vh;
  align-items: center;

  > div {
    text-align: center;

    ${BodyLarge} {
      margin-bottom: 24px;
    }

    a {
      margin-top: 40px;
    }
  }
`

export function Head() {
  return <Seo title={"404"} />
}

export default function GenericTemplate() {
  return (
    <Layout>
      <Wrapper>
        <div>
          <BodyLarge>
            There’s no alcohol in Drømme, and there’s no page by that name
            (that’s a 404).
          </BodyLarge>
          <Body>
            Head on home or, better yet, shop and get some Drømme in your home.
          </Body>
          <Button as={Link} to="/">
            Return Home
          </Button>
        </div>
      </Wrapper>
    </Layout>
  )
}
